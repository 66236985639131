import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter
} from 'react-router-dom';
import Loadable from 'react-loadable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-modal-slider/lib/main.css';
import Notifications, { notify } from 'react-notify-toast';
import async from 'async';
import _ from 'lodash';
import LoadingComponent from './components/Loader/BasicLoader';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import Sidebar from './components/Sidebar/Sidebar';
import Login from './components/Login/Login';
import callApi from './util/salesAPICaller';
import callIdApi from "./util/idApiCaller";
import callEventApi from './util/eventApiCaller';
import AccessControl from './components/PermissionLocks/AccessControl';
import PageAccessControl from "./components/PermissionLocks/PageAccessControl";
import sidebarLinks from './constants/sidebar';
import BreadCrumb from "./components/BreadCrumb";
import utility, { searchParams } from "./util/utility";
import PubNubReact from 'pubnub-react';
import moment from 'moment';
import { version } from '../package.json';
import AgentSidebar from './components/OnlineAgentsSidebar/AgentSidebar';
import Connector from './Connector';
import AppVersionModal from './components/AppVersionModal';
import { AGENTVERSIONV1, INSIDE_SALES, SALES, SALES_TRACKING_INTERVAL, VISIT_SALES } from './constants/enum';
import { fetchConfigs, fetchOperationalCityList, getLocationData, setLocationData } from './redux/admin/AppActions';
import { connect } from 'react-redux';
import ResetPassword from './components/ResetPassword/ResetPassword';
import { getConfigData, getlocation } from './redux/admin/AppReducer';
import showToastMessage from './util/notificationUtil';
import InactivityCounterModal from './modules/Admin/InactivityCounter/InactivityCounterModal';
import dayjs from 'dayjs';
import { cl, di } from '@fullcalendar/core/internal-common';
import { REFETCH_LOCATIONS_AT_INTERVAL, REFETCH_LOCATIONS_INTERVAL } from './constants/LocationConfig';
import { AUTOLOGOUT_USER_ON_INACTIVITY } from './constants/autologout';
import GoogleAuthSetup from './components/GoogleAuthSetup/GoogleAuthSetup';
import { getAvailaibilityCalendarVisibilityState } from './redux/admin/AvailaibilityCalendarReducer';
import CalendarDrawer from './components/EventModalForm/components/CalendarDrawer';
import callmessageApi from './util/messageApiCaller';


const DIALER_PUBNUB_PUBLISHER_KEY = 'pub-c-c2d3bd6d-c78c-4ca0-be50-b342ce4744ed';
const DIALER_PUBNUB_SUBSCRIBER_KEY = 'sub-c-bee3adec-2f16-11ea-aaf2-c6d8f98a95a1';

const PROTOCOL = window.location.protocol;
const HOST = window.location.host;
const endpoint = `${process.env.REACT_APP_API_URL}`;
const TIMEOUT = process.env.REACT_APP_LOGOUT_TIMER
const INACTIVITY_COUNTER = TIMEOUT - 15000
let inactivityTimeout;
let countDownCounter;
let locationInterval;


let socket;

const AsyncProfile = Loadable({
  loader: () => import('./modules/Profile/Profile/Profile'),
  loading: LoadingComponent,
});
const AsyncUserSettings = Loadable({
  loader: () => import('./modules/Profile/UserSettings/UserSettings'),
  loading: LoadingComponent,
});

const AsyncAdminAccessList = Loadable({
  loader: () => import('./modules/Admin/AdminAccessList/AdminAccessList'),
  loading: LoadingComponent,
});
const AsyncVendorPhotographerList = Loadable({
  loader: () => import('./modules/Admin/VendorPhotographerList/VendorPhotographerList'),
  loading: LoadingComponent,
});
const AsyncVendorMehendiList = Loadable({
  loader: () => import('./modules/Admin/VendorMehendiList/VendorMehendiList'),
  loading: LoadingComponent,
});
const AsyncAgentBdDashboard = Loadable({
  loader: () => import('./modules/Admin/AgentBdDashboard/AgentBdDashboard'),
  loading: LoadingComponent,
});
const AsyncVendorMakeupList = Loadable({
  loader: () => import('./modules/Admin/VendorMakeupList/VendorMakeupList'),
  loading: LoadingComponent,
});
const NotFoundVenueList = Loadable({
  loader: () => import('./modules/Feedback/NotFoundVenueList/NotFoundVenueList'),
  loading: LoadingComponent,
});
const FutureEventList = Loadable({
  loader: () => import('./modules/Feedback/FutureEventList/FutureEventList'),
  loading: LoadingComponent,
});

const AsyncAgentEnquiries = Loadable({
  loader: () => import('./modules/Admin/AgentEnquiries/AgentEnquiries'),
  loading: LoadingComponent,
});
const AsyncVisitAgentEnquiries = Loadable({
  loader: () => import('./modules/Admin/Visits/MyVisitsEnquiries'),
  loading: LoadingComponent,
});
const AsyncAllEnquiries = Loadable({
  loader: () => import('./modules/Admin/AgentEnquiries/AllLeadsEnquiries'),
  loading: LoadingComponent,
});
const AsyncAllMakeUpEnquiries = Loadable({
  loader: () => import('./modules/Admin/AgentEnquiries/AllMakeUpEnquiries'),
  loading: LoadingComponent,
});
const AsyncAllVisitEnquiries = Loadable({
  loader: () => import('./modules/Admin/Visits/AllVisitsEnquiries'),
  loading: LoadingComponent,
});
const AsyncAgentList = Loadable({
  loader: () => import('./modules/Admin/AgentList/AgentList'),
  loading: LoadingComponent,
});
const AsyncAgentOnlineList = Loadable({
  loader: () => import('./modules/Admin/AgentOnlineList/AgentOnlineList'),
  loading: LoadingComponent,
});
const AsyncAgentCallData = Loadable({
  loader: () => import('./modules/Admin/AgentCallData/AgentCallData'),
  loading: LoadingComponent,
});
const AsyncAgentLeadDashboard = Loadable({
  loader: () => import('./modules/Admin/AgentLeadDashboard/AgentLeadDashboard'),
  loading: LoadingComponent,
});
const AsyncMyAgentCallData = Loadable({
  loader: () => import('./modules/Admin/MyAgentCallData/MyAgentCallData'),
  loading: LoadingComponent,
});

const AsyncLoginData = Loadable({
  loader: () => import('./modules/Admin/LoginData/LoginData'),
  loading: LoadingComponent,
});
const AsyncAgentsLoginData = Loadable({
  loader: () => import('./modules/Admin/AgentsLoginData/AgentsLoginData'),
  loading: LoadingComponent,
});
const AsyncPreVenue = Loadable({
  loader: () => import('./modules/Onboarding/PreVenue/PreVenue'),
  loading: LoadingComponent,
});
const AsyncPreVenueList = Loadable({
  loader: () => import('./modules/Onboarding/PreVenueList/PreVenueList'),
  loading: LoadingComponent,
});

const AsyncAdminDashboard = Loadable({
  loader: () => import('./modules/Admin/AdminDashboard/AdminDashboard'),
  loading: LoadingComponent,
});

const AsyncBundleSetList = Loadable({
  loader: () => import('./modules/CRM/BundleSetList/BundleSetList'),
  loading: LoadingComponent,
});
const AsyncBundleSetUpdator = Loadable({
  loader: () => import('./modules/CRM/BundleSetUpdator/BundleSetUpdator'),
  loading: LoadingComponent,
});
const AsyncAgentFeedbackDashboard = Loadable({
  loader: () => import('./modules/Admin/AgentFeedbackDashboard/AgentFeedbackDashboard'),
  loading: LoadingComponent,
});
const AsyncFunnelSystemList = Loadable({
  loader: () => import('./modules/CRM/FunnelSystemList/FunnelSystemList'),
  loading: LoadingComponent,
});
const AsyncFunnelUpdator = Loadable({
  loader: () => import('./modules/CRM/FunnelUpdator/FunnelUpdator'),
  loading: LoadingComponent,
});
const AsyncVenueTrackerList = Loadable({
  loader: () => import('./modules/Onboarding/VenueTrackerList/VenueTrackerList'),
  loading: LoadingComponent,
});

const AsyncUserList = Loadable({
  loader: () => import('./modules/Admin/UserList/UserList'),
  loading: LoadingComponent,
});

const AsyncRoleList = Loadable({
  loader: () => import('./modules/Admin/RoleList/RoleList'),
  loading: LoadingComponent,
});
const AsyncRoleGroupList = Loadable({
  loader: () => import('./modules/Admin/RoleGroupList/RoleGroupList'),
  loading: LoadingComponent,
});

const AsyncModuleList = Loadable({
  loader: () => import('./modules/Admin/ModuleList/ModuleList'),
  loading: LoadingComponent,
});
const AsyncAgentCorpDashboard = Loadable({
  loader: () => import('./modules/Admin/AgentCorpDashboard/AgentCorpDashboard'),
  loading: LoadingComponent,
});
const AsyncFeedbackList = Loadable({
  loader: () => import('./modules/Leads/FeedbackList/FeedbackList'),
  loading: LoadingComponent,
});
const AsyncPartnerAppModuleList = Loadable({
  loader: () => import('./modules/Admin/PartnerAppModuleList/ModuleList'),
  loading: LoadingComponent,
});
const AsyncVenueLeadTargetList = Loadable({
  loader: () => import('./modules/Leads/VenueLeadTarget/VenueLeadTargetList'),
  loading: LoadingComponent,
});
const AsyncEventWonList = Loadable({
  loader: () => import('./modules/Leads/NewEventWonList/NewEventWonList'),
  loading: LoadingComponent,
});
const AsyncEventLostList = Loadable({
  loader: () => import('./modules/Leads/EventLostList/EventLostList'),
  loading: LoadingComponent,
});
const AsyncApprovalAgentList = Loadable({
  loader: () => import('./modules/Admin/ApprovalAgentList/ApprovalAgentList'),
  loading: LoadingComponent,
});
const AsyncApprovalList = Loadable({
  loader: () => import('./modules/Admin/Approvals/ApprovalList'),
  loading: LoadingComponent,
});

const AsyncPermissionList = Loadable({
  loader: () => import('./modules/Admin/PermissionList/PermissionList'),
  loading: LoadingComponent,
});

const AsyncMyFeedbackList = Loadable({
  loader: () => import('./modules/MyFeedback/MyFeedbackList/MyFeedbackList'),
  loading: LoadingComponent,
});

const AsyncSalesAgentQueueSystem = Loadable({
  loader: () => import('./modules/Admin/SalesAgentQueueSystem/SalesAgentQueueSystem'),
  loading: LoadingComponent,
});

const AsyncSalesAgentQueueSystemV2 = Loadable({
  loader: () => import('./modules/Admin/SalesAgentQueueSystem/SalesAgentQueueSystemV2'),
  loading: LoadingComponent,
});
const AsyncSalesAgentQueueSystemV3 = Loadable({
  loader: () => import('./modules/Admin/SalesAgentQueueSystem/SalesAgentQueueSystemV3'),
  loading: LoadingComponent,
});

const AsyncSalesAgentQueueSystemSearch = Loadable({
    loader: () => import('./modules/Admin/SalesAgentQueueSystem/SalesAgentQueueSearchSystem'),
    loading: LoadingComponent,
});


const AsyncImpTasks = Loadable({
  loader: () => import('./modules/Admin/ImpTasks/ImpTasks'),
  loading: LoadingComponent,
});

const AsyncAdminNotifications = Loadable({
  loader: () => import('./modules/Admin/AdminNotifications/AdminNotifications'),
  loading: LoadingComponent,
});

const PrivateRoute = ({
  component: Component,
  isAuthenticated: isAuthenticated,
  userPermissions,
  shouldHavePermissions,
  ...rest
}) => (
  <Route {...rest} render={(props) => (
    isAuthenticated
      ? <PageAccessControl
        userPermissions={userPermissions}
        shouldHavePermissions={shouldHavePermissions}
      >
        <Component {...props} />
      </PageAccessControl>
      : <Redirect to={{
        pathname: '/login',
        state: { _refurl: props.location }
      }} />
  )} />
);


class App extends Component {
  constructor() {
    super();
    this.baseBreadCrumbs = [
      {
        link: "/profile",
        name: "Home",
        isActive: false,
      },
    ];
    this.state = {
      isMounted: false,
      isLoading: false,
      isGoogleAuthSetupDone: null,
      userid: "",
      agentid: "",
      user: {},
      agent: {},
      isAuthenticated: false,
      token: "",
      notification: {
        message: "",
        link: "",
        timeout: 3,
        msgId: "",
      },
      hasAccessToSideBar:true,
      cities: [],
      contents: [],
      userPermissions: [],
      isLoggingIn: false,
      links: [],
      agentVersion: AGENTVERSIONV1,
      breadcrumbs: [
        {
          link: "/profile",
          name: "Home",
          isActive: false,
        },
      ],
      showInactivityCounter: false,
      tabHideTime: null
    };
    this.isAuthenticated = false;
    this.dialerPubnub = new PubNubReact({
      publishKey: DIALER_PUBNUB_PUBLISHER_KEY,
      subscribeKey: DIALER_PUBNUB_SUBSCRIBER_KEY,
    });
    this.dialerPubnub.init(this)

  }




  resetInactivityTimer = () => {
    clearTimeout(inactivityTimeout);

    if (this.state.showInactivityCounter) {
      this.setState({
        showInactivityCounter: false
      })
    }
    clearTimeout(countDownCounter)
    countDownCounter = setTimeout(() => {
      this.setState({
        showInactivityCounter: true
      })
    }, INACTIVITY_COUNTER)
    inactivityTimeout = setTimeout(
      () => this.logoutUser("autoLoggedOut", "inactivity"),
      TIMEOUT
    );

  };

  handleVisibilityChange = () => {
    if (document.hidden) {
      localStorage.setItem("tabHideTime", dayjs(new Date()))
      clearTimeout(inactivityTimeout)
      clearTimeout(countDownCounter)
    }
    else {
      let currentDate = dayjs(new Date())
      let tabHideTime = localStorage.getItem("tabHideTime")
      let diff = currentDate.diff(tabHideTime, "ms")
      if (diff > TIMEOUT) {
        let adjustedDiff = diff - TIMEOUT
        let adjustedTime = dayjs().subtract(adjustedDiff, "ms").toISOString()
        this.logoutUser("exception", "inactivity", _, adjustedTime)
      }
      else {
        localStorage.removeItem("tabHideTime")
        this.resetInactivityTimer()
      }
    }
  }

  fetchLocationAtInterval = () => {
    try {
      if (!locationInterval && REFETCH_LOCATIONS_AT_INTERVAL) {
        locationInterval = setInterval(() => {
          this.props.dispatch(getLocationData())
        }, REFETCH_LOCATIONS_INTERVAL)
      }
    }
    catch (e) {
      this.logoutUser("autoLoggedOut", "locationError")
      console.log(e)
    }

  }

  documentActionListeners = () => {
    if (AUTOLOGOUT_USER_ON_INACTIVITY) {
      this.resetInactivityTimer();
      document.addEventListener("mousemove", () =>
        this.resetInactivityTimer()
      );
      document.addEventListener('visibilitychange', () => this.handleVisibilityChange());
    }
  }


  userLoginDetails = (agentId, appSource, payload) => {
    try {
      callEventApi(`v1/agent-active-times/${agentId}/track`, 'POST', {
        app_source: appSource,
        ...payload
      })
    }
    catch (e) {
      console.log(e)
    }

  }

  mountComponent = () => {
    let fe = searchParams.fe
    if(fe && fe=="1"){
      let tempToken = window.localStorage.getItem("temp-token")
      if(tempToken){
        let parsedTemp =  JSON.parse(tempToken);
        if(parsedTemp.ttl && !dayjs().isAfter(dayjs(parsedTemp.ttl))){
          window.sessionStorage.setItem("token",parsedTemp.token)
        }
      }
      this.setState({
        hasAccessToSideBar:false
      })
    }
    let token = window.sessionStorage.getItem("token")
    let xToken = window.localStorage.getItem("x-token")
    if (token && token !== "") {
      this.setState({
        isLoading: true,
      });
      // socket = window.io(endpoint);
      this.verifyToken(token, xToken, (err, user) => {
        if (err) {
          window.localStorage.removeItem("x-token")
          window.localStorage.removeItem("token")
        } else {
          this.isAuthenticated = true;
          let model = {
            user: user,
          };
          if (!_.isEmpty(model.user)) {
            this.setState(
              {
                isMounted: true,
                isLoading: false,
                isAuthenticated: true,
                userid: model.user._id,
                agentid: model.user._id,
                token: token,
                user: model.user,
                agent: model.user,
                agentVersion: model.user?.sales_app_version ? model.user?.sales_app_version : AGENTVERSIONV1
              },
              () => {
                let uniqueid = localStorage.getItem("unique_device_id")
                if (!uniqueid || uniqueid == undefined || uniqueid == null || uniqueid == "") {
                  localStorage.setItem("unique_device_id", this.generateMessageId(6).toString())
                }
                this.documentActionListeners();
                let payload = {
                  agent_name: user.name,
                  unique_device_id: localStorage.getItem("unique_device_id")
                }
                this.loginTrackingIntervel = setInterval(() => this.userLoginDetails(model.user._id, SALES, payload), SALES_TRACKING_INTERVAL);
                async.series(
                  [
                    (cb) => {
                      if (model.user && model.user.agentid) {
                        this.fetchAgent(
                          model.user.agentid,
                          (err, agent) => {
                            if (!err && agent) {
                              model.agent = agent;
                              return cb();
                            } else {
                              return cb();
                            }
                          }
                        );
                      } else {
                        return cb();
                      }
                    },
                    (cb) => {
                      if (
                        model.user &&
                        model.user.venue_list &&
                        model.user.venue_list !== ""
                      ) {
                        this.fetchAccessList(
                          model.user.venue_list,
                          (err, access) => {
                            if (!err && access) {
                              model.access = access;
                              return cb();
                            } else {
                              return cb();
                            }
                          }
                        );
                      } else {
                        return cb();
                      }
                    },
                    (cb) => {
                      this.fetchCityList((err, cities) => {
                        if (!err && cities && cities.length > 0) {
                          model.cities = cities.map((item) => {
                            return {
                              name: _.startCase(item.name),
                              sname: item.name,
                            };
                          });
                          return cb();
                        } else {
                          return cb();
                        }
                      });
                    },
                    (cb) => {
                      this.fetchContentList((err, contents) => {
                        if (!err && contents && contents.length > 0) {
                          model.contents = contents;

                          return cb();
                        } else {
                          return cb();
                        }
                      });
                    },
                  ],
                  (err) => {
                    if (err) {
                      this.setState({
                        isLoading: false,
                      });
                    } else {
                      let userPermissions = [];
                      if (
                        model.user.userPermissions &&
                        _.isArray(model.user.userPermissions)
                      ) {
                        userPermissions = userPermissions.concat(
                          model.user.userPermissions
                        );
                      }
                      if (
                        model.user.extraPermissions &&
                        _.isArray(model.user.extraPermissions)
                      ) {
                        userPermissions = userPermissions.concat(
                          model.user.extraPermissions
                        );
                      }
                      this.setState({
                        isLoading: false,
                        user: model.user ? model.user : {},
                        userPermissions: userPermissions,
                        access: model.access ? model.access : {},
                        contents: model.contents,
                        cities: model.cities,
                        links: sidebarLinks,
                      });
                      if (this.state.agentid) {
                        this.dialerPubnub.subscribe({
                          channels: [`dialer-${this.state.agentid}`],
                          withPresence: false,
                        });
                      }
                    }
                  }
                );
              }
            );
          }
        }
      });
      // socket.on('MarkedOnline', function(data){
      //     console.log('socket:' + socket.id);
      // })
      this.props.dispatch(fetchOperationalCityList());
      //  ""
    }
  }

  componentWillUnmount() {
    clearInterval(this.loginTrackingIntervel);
  }



  componentDidMount() {
    this.props.dispatch(fetchConfigs()).then(() => {
      if (this.props?.config?.is_location_required_at_login) {
        this.fetchLocationAtInterval()
        let location = window.sessionStorage.getItem("location")
        if (!location) {
          this.props
            .dispatch(getLocationData())
            .then(() => {
              window.sessionStorage.setItem("location", JSON.stringify(this.props.location))
              this.mountComponent()
              console.log("App is running on version :::: ", version);
            }
            )
            .catch((e) => {
              console.log(e);
            });

        } else {
          this.props.dispatch(setLocationData(JSON.parse(location)))
            .then(() => {
              this.mountComponent()


            })
        }


      }
      else {
        this.mountComponent()
      }
    }).catch((e) => {
      console.log(e)
      this.mountComponent()
    })

  }




  checkIfHasLocation = (longitude, latitude) => {
    if (
      longitude &&
      latitude &&
      !longitude.toString().trim("") == "" &&
      !latitude.toString().trim("") == ""
    ) {
      return true;
    } else return false;
  };

  fetchContentList = (next) => {
    callApi(`v2/contents/list`, "post", {
      pageSize: 100
    }).then((res) => {
      return next(null, res.data.contents);
    });
  };

  updateUser = (user) => {
    this.setState({ user: user });
  };

  updateUserpref = (userpref) => {
    callApi(`v2/userpref/${this.state.userprefId}/update`, "POST", {
      userpref: userpref,
    }).then((res) => {
      if (res.status === "Success") {
        this.setState({
          faveLinks: res.data.userpref.faveLinks,
        });
        this.showMessage("Links Updated", "success");
      }
    });
  };

  updateAgent = (agent) => {
    callEventApi(`v2/agents/${this.state.agentid}/update`, "POST", {
      agent: agent,
    }).then((res) => {
      if (res.status === "Success") {
        this.setState({
          agent: res.data.agent,
        });
        this.showMessage("Updated", "success");
      }
    });
  };

  fetchCityList = (next) => {
    callApi(`v3/citys/city-list`, "GET").then((res) => {
      return next(null, res.data.cities);
    });
  };

  fetchAgent = (agentid, next) => {
    callEventApi(`v2/agents/${agentid}`, "GET").then((res) => {
      return next(null, res.data.agent);
    });
  };

  callLoginApi = async (creds) => {
    this.setState({
      isLoggingIn: true,
    });

    try {
      creds.location = this.props.location;
      const res = await callIdApi(`auth/login?app=${SALES}`, "post", creds);

      this.setState({
        isLoggingIn: false,
      });

      if (res.user && res.user.ok) {
        window.sessionStorage.setItem("token", res.token);
        let tempToken = {}
        tempToken.token = res.token
        tempToken.ttl = dayjs().add(4,"hours").toISOString(); 
        window.localStorage.setItem("temp-token", JSON.stringify(tempToken));
        let agent = res?.user?.data;
        let user_id = agent?._id;
        window.localStorage.setItem("user_id", user_id?.toString())
        if (agent?.is_2fa_required) {
          if (!agent?.is_2fa_enabled) {

            window.location = `/validate-2fa`;
          } else {
            let xToken = localStorage.getItem("x-token");

            if (xToken) {
              window.location = "/";
            } else {
              return {
                message: "request_otp",
                agentId: agent?._id,
              };
            }
          }
        } else {
          window.location = "/";
        }
      } else {
        this.showMessage(res.data?.message, "error");
      }
    } catch (e) {
      console.log(e);
      notify.show("error in logging in info");
    }
  };


  loginUser = async (creds) => {
    if (this.props?.config?.is_location_required_at_login) {
      if (this.checkIfHasLocation(this.props.location?.longitude, this.props.location?.longitude)) {
        let res = await this.callLoginApi(creds)
        if (res) {
          return res
        }
      } else {
        notify.show("please allow location to continue")
      }
    } else {
      this.callLoginApi(creds)
    }
  };

  verifyToken = (token, xToken, next) => {
    callIdApi(`v2/auth/verify?app=${SALES}`, "post", {
      token: token,
      x_token: xToken
    }).then((res) => {
      if (res.user && res.user.ok) {
        let user = res.user.data;
        if (user.is_2fa_required && !user.is_2fa_enabled) {
          return next("Unauthorized")
        }
        return next(null, user);
        // window.sessionStorage.removeItem('token')

      } else {
        return next("Unauthorized");
      }
    });
  };

  resetToMainScreen = (resetXToken) => {
    window.sessionStorage.removeItem("hasLocation")
    window.sessionStorage.removeItem("token");
    if (resetXToken) {
      window.localStorage.removeItem("x-token")
    }
    window.sessionStorage.removeItem("location")

    this.isAuthenticated = false;


    window.location = "/";
  };
  logoutUser = async (type, reson_text, reson_code, logoutTime) => {
    try {
      switch (type) {

        case "autoLoggedOut": {
          let res = await callIdApi(
            `auth/agent/${this.state.agentid}/logout?app=sales&autoLoggedOut=true&reason_text=${reson_text}`,
            "POST",
            { location: this.props.location }
          );
          if (res.status == "Success") {
            setTimeout(() => this.resetToMainScreen(), 100)
          }
          break;

        }

        case "willFullLogout": {
          let res = await callIdApi(
            `auth/agent/${this.state.agentid}/logout?app=sales`,
            "POST",
            { location: this.props.location }
          );
          if (res.status == "Success") {

            notify.show("logout Success", "success")
            this.resetToMainScreen(true)
          }
          else {
            notify.show("Error While Logging Out")
          }
          break;
        }


        case "break": {
          let res = await callIdApi(
            `auth/agent/${this.state.agentid}/break?app=sales&reason_code=${reson_code}&reason_text=${reson_text}`,
            `POST`,
            { location: this.props.location }
          );
          if (res.status == "Success") {
            this.resetToMainScreen(true)
            notify.show("Success Break", 'success', "3000")

          }
          else (notify.show("Error while Logging Out", "error"))
          break;
        }

        case "exception": {
          await callIdApi(
            `auth/agent/${this.state.agentid}/logout?app=sales&autoLoggedOut=true&reason_text=${reson_text}&exception=true`,
            "POST",
            { location: this.props.location, logout_date: logoutTime }

          );
          setTimeout(() => this.resetToMainScreen(), 100)
          break;
        }
      }
    }
    catch (e) {
      console.log(e)
    }



    // if (socket && this.state.agentid) {
    //     this.setAgentOffline(this.state.agentid, socket.id);
    // }

    // // });
  };

  showMessage = (msg, msgtype) => {
    notify.show(msg, msgtype, 3000);
  };

  notifyToAgent = (msg) => {
    let text = msg.text;
    let options = {};
    if (msg.isTimedNotification) {
      options.autoClose = msg.timer;
    } else {
      options.autoClose = false;
    }
    if (msg.notification_type === "success") {
      toast.success(text, options);
    } else {
      toast.info(text, options);
    }
  };

  clearAgentNotifications = () => {
    toast.dismiss();
  };

  sendForgetPassword = async (email) => {

    try {

      let res = await callIdApi(`auth/forgot-password`, `post`,
        { email: email, app: "sales" }
      )
      if (res.status = "Success") {
        notify.show("email sent success", "success", 3000)
      }
      else {
        notify.show("error in sending email")
      }
    }
    catch (e) {
      console.log(e)
    }


  }

  fetchAccessList = (id, next) => {
    callApi(`v2/venueaccesses/${id}/populated`, "get").then((res) => {
      if (res.status === "Success") {
        let access = res.data.venueaccess;
        return next(null, access);
      } else {
        return next("Unauthorized");
      }
    });
  };

  notifyToAdmin = (message, link, timeout) => {
    let notification = this.state.notification;
    notification.message = message;
    notification.link = link ? link : "";
    notification.timeout = timeout;
    notification.msgId = this.generateMessageId(8);
    this.setState({
      notification: notification,
    });
  };

  rand = (min, max) => {
    let random = Math.random();
    return Math.floor(random * (max - min) + min);
  };

  generateMessageId = (length) => {
    let loopLength = length || 8;
    let allowsChars = "0123456789";
    let password = "";
    for (let index = 0; index < loopLength; ++index) {
      let charIndex = this.rand(0, allowsChars.length - 1);
      password += allowsChars[charIndex];
    }
    return password;
  };

  setBreadCrumbs = (breadcrumbs) => {
    this.setState({
      breadcrumbs: breadcrumbs,
    });
  };

  resetBreadCrumbs = () => {
    this.setState({
      breadcrumbs: this.baseBreadCrumbs,
    });
  };

  // sendPubNubMessage = ( message) => {
  //     this.pubnub.publish(
  //         {
  //             channel: channels[0],
  //             message,
  //         },
  //         () => {
  //
  //         }
  //     );
  // };

  render() {
    console.log("appp state changed")
    const cities = this.state.cities;
    return (
      <div className="App">
        {this.state.showInactivityCounter && (
          <InactivityCounterModal
            open={this.state.showInactivityCounter}
            duration={15}
            onClose={() => {
              this.setState({
                showInactivityCounter: false
              })
            }}
          />



        )}
        <Router history={this.props.history}>
          <div id="page-content-wrapper">
            {this.state.isAuthenticated ?
              <header id="topnav"
                className="navbar navbar-default navbar-fixed-top vm-navbar-z-index"
                role="banner">
                <Connector
                  agentid={this.state.agentid}
                  agent={this.state.agent}
                  showMessage={this.showMessage}
                  userPermissions={this.state.userPermissions}
                  cities={cities ? cities : []}
                />
                <Header
                  notification={this.state.notification}
                  fetchAgentUnReadMessageCount={this.fetchAgentUnReadMessageCount}
                  user={this.state.user}
                  showMessage={this.showMessage}
                  notifyToAdmin={this.notifyToAgent}
                  clearAgentNotifications={this.clearAgentNotifications}
                  logOut={this.logoutUser}
                  agentid={this.state.agentid}
                />
              </header>
              :
              null
            }
            <div id="wrapper">
              <div id="layout-static">
                {this.state.isAuthenticated  && this.state.hasAccessToSideBar ?
                  <Sidebar
                    links={this.state.links}
                    userPermissions={this.state.userPermissions}
                  />
                  :
                  null
                }
                <div id="wrapper">
                  <div id="layout-static">
                    {this.state.isAuthenticated ?
                      <AgentSidebar
                        links={this.state.links}
                        userPermissions={this.state.userPermissions}
                        agentid={this.state.agentid}
                        user={this.state.user}
                        cities={cities ? cities : []}
                        userid={this.state.userid}
                        agent={this.state.agent}
                      />
                      :
                      null
                    }
                    <div className="static-content-wrapper">
                      <div className="static-content">
                        <div className="page-content">
                          <BreadCrumb
                            breadcrumbs={this.state.breadcrumbs}
                          />
                          <div className="container-fluid">
                            <Switch>
                              <Route exact path="/reset-password"
                                render={props =>
                                  <ResetPassword {...props}
                                  />}
                              />
                              <Route exact path="/login"
                                render={props =>
                                  <Login {...props}
                                    sendForgetPassword={this.sendForgetPassword}
                                    resetToMainScreen={this.resetToMainScreen}
                                    isLoggingIn={this.state.isLoggingIn}
                                    isAuthenticated={this.state.isAuthenticated}
                                    user={this.state.user}
                                    loginUser={this.loginUser}
                                  />}

                              />

                              <Route
                                y exact path="/validate-2fa"
                                render={props =>
                                  <GoogleAuthSetup {...props}



                                  />}

                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={null}
                                isAuthenticated={this.state.isAuthenticated}
                                exact path="/"
                                component={props =>
                                  <AsyncProfile
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    links={this.state.links}
                                    agent={!_.isEmpty(this.state.agent) ? this.state.agent : {}}
                                    user={this.state.user}
                                    updateUser={this.updateUser}
                                  />} />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/funnel/corp-board"
                                component={props =>
                                  <AsyncAgentCorpDashboard {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    cities={cities ? cities : []}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent} />}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/funnel/feedback-board"
                                component={props =>
                                  <AsyncAgentFeedbackDashboard {...props}
                                    showMessage={this.showMessage}
                                    cities={cities ? cities : []}
                                    userPermissions={this.state.userPermissions}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent} />}
                              />
                              <Redirect
                                path={'/profile'}
                                to={"/"} />
                              <Redirect
                                path={'/manager/profile'}
                                to={"/"} />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/enquiry/closed-won"
                                component={(props) => (
                                  <AsyncEventWonList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    cities={cities ? cities : []}
                                    showMessage={this.showMessage}
                                    userid={this.state.userid}
                                  />
                                )}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/enquiry/feedback"
                                component={AsyncFeedbackList}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/enquiry/feedback"
                                component={AsyncFeedbackList} />
                              <PrivateRoute
                                isAuthenticated={this.state.isAuthenticated}
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[]}
                                exact
                                path="/manager/leads/venuetargetleads"
                                component={AsyncVenueLeadTargetList}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/enquiry/closed-won"
                                component={props =>
                                  <AsyncEventWonList {...props}
                                    cities={cities ? cities : []}
                                    showMessage={this.showMessage}
                                    userid={this.state.userid}
                                  />}
                              />

                              <PrivateRoute
                                isAuthenticated={this.state.isAuthenticated}
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[]}
                                path="/manager/notifications"
                                component={props =>
                                  <AsyncAdminNotifications {...props}
                                    user={this.state.user} 
                                    userid={this.state.userid}
                                    showMessage={this.showMessage}
                                    />}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["myleads:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/enquiry/my-leads-board"
                                component={props =>
                                  <AsyncAgentEnquiries {...props}
                                    userPermissions={this.state.userPermissions}
                                    cities={cities ? cities : []}
                                    contents={this.state.contents}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent} />} />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["myleads:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/impTasks/board"
                                component={props =>
                                  <AsyncImpTasks {...props}
                                    userPermissions={this.state.userPermissions}
                                    cities={cities ? cities : []}
                                    contents={this.state.contents}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent} />} />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["myleads:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/enquiry/my-visits-board"
                                component={props =>
                                  <AsyncVisitAgentEnquiries {...props}
                                    userPermissions={this.state.userPermissions}
                                    cities={cities ? cities : []}
                                    contents={this.state.contents}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent} />} />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["myleads:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/enquiry/leads-board"
                                component={props =>
                                  <AsyncAllEnquiries {...props}
                                    userPermissions={this.state.userPermissions}
                                    cities={cities ? cities : []}
                                    contents={this.state.contents}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent} />} />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["myleads:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/enquiry/make-up-lead-board"
                                component={props =>
                                  <AsyncAllMakeUpEnquiries {...props}
                                    userPermissions={this.state.userPermissions}
                                    cities={cities ? cities : []}
                                    contents={this.state.contents}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent} />} />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["myleads:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/enquiry/visits-board"
                                component={props =>
                                  <AsyncAllVisitEnquiries {...props}
                                    userPermissions={this.state.userPermissions}
                                    cities={cities ? cities : []}
                                    contents={this.state.contents}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent} />}
                                showMessage={this.showMessage}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["myleads:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/events/:id/update"
                                component={(props) => (
                                  <AsyncAdminDashboard
                                    {...props}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/enquiry/closed-lost"
                                component={(props) => (
                                  <AsyncEventLostList
                                    {...props}
                                    cities={cities ? cities : []}
                                    showMessage={this.showMessage}
                                    userid={this.state.userid}
                                  />
                                )}
                              />

                              {/*<PrivateRoute*/}
                              {/*    isAuthenticated={this.state.isAuthenticated}*/}
                              {/*    userPermissions={this.state.userPermissions}*/}
                              {/*    shouldHavePermissions={["mytasks:read"]}*/}
                              {/*    path="/manager/tasks/board"*/}
                              {/*    component={props =>*/}
                              {/*        <AsyncAgentLeadDashboard {...props}*/}
                              {/*                                 contents={this.state.contents}*/}
                              {/*                                 showMessage={this.showMessage}*/}
                              {/*                                 userPermissions={this.state.userPermissions}*/}
                              {/*                                 user={this.state.user}*/}
                              {/*                                 agentid={this.state.agentid}*/}
                              {/*                                 userid={this.state.userid}*/}
                              {/*                                 cities={cities ? cities : []}*/}
                              {/*                                 agent={this.state.agent}/>}/>*/}

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/enquiry/closed-won"
                                component={(props) => (
                                  <AsyncEventWonList
                                    {...props}
                                    cities={cities ? cities : []}
                                    showMessage={this.showMessage}
                                    userid={this.state.userid}
                                  />
                                )}
                              />
                              <PrivateRoute
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/call/info"
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[]}
                                component={(props) => (
                                  <AsyncMyAgentCallData
                                    {...props}
                                    showMessage={this.showMessage}
                                    userPermissions={this.state.userPermissions}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    cities={cities ? cities : []}
                                    agent={this.state.agent}
                                  />
                                )}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/agents/call-data"
                                component={props =>
                                  <AsyncAgentCallData {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    cities={cities ? cities : []}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent} />}
                              />
                              <PrivateRoute
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/login/info"
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[]}
                                component={(props) => (
                                  <AsyncLoginData
                                    {...props}

                                    showMessage={this.showMessage}
                                    userPermissions={this.state.userPermissions}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    cities={cities ? cities : []}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/agents/login/info"
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["agentlogin:read"]}
                                component={(props) => (
                                  <AsyncAgentsLoginData
                                    {...props}

                                    showMessage={this.showMessage}
                                    userPermissions={this.state.userPermissions}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    cities={cities ? cities : []}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              {/*admin*/}
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/users/list"
                                component={(props) => (
                                  <AsyncUserList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/admin/role/list"
                                component={(props) => (
                                  <AsyncRoleList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/funnel/sales-board"
                                component={(props) => (
                                  <AsyncSalesAgentQueueSystem
                                    {...props}
                                    agent_type={"inside-sales"}
                                    contents={this.state.contents}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    cities={cities ? cities : []}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              {/*<PrivateRoute*/}
                              {/*    userPermissions={this.state.userPermissions}*/}
                              {/*    shouldHavePermissions={[]}*/}
                              {/*    isAuthenticated={this.state.isAuthenticated}*/}
                              {/*    exact*/}
                              {/*    path="/manager/funnel/inside-sales"*/}
                              {/*    component={props =>*/}
                              {/*        <AsyncSalesAgentQueueSystemV2 {...props}*/}
                              {/*                                      agent_type={INSIDE_SALES}*/}
                              {/*                                      contents={this.state.contents}*/}
                              {/*                                      userPermissions={this.state.userPermissions}*/}
                              {/*                                      showMessage={this.showMessage}*/}
                              {/*                                      user={this.state.user}*/}
                              {/*                                      cities={cities ? cities : []}*/}
                              {/*                                      agentid={this.state.agentid}*/}
                              {/*                                      userid={this.state.userid}*/}
                              {/*                                      agent={this.state.agent}/>}*/}
                              {/*/>*/}
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/tasks/board"
                                component={(props) => (
                                  <AsyncSalesAgentQueueSystemV3
                                    {...props}
                                    agent_type={INSIDE_SALES}
                                    contents={this.state.contents}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    cities={cities ? cities : []}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                    agentVersion={this.state.agentVersion}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/tasks/board/search"
                                component={(props) => (
                                  <AsyncSalesAgentQueueSystemSearch
                                    {...props}
                                    agent_type={INSIDE_SALES}
                                    contents={this.state.contents}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    cities={cities ? cities : []}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                    agentVersion ={this.state.agentVersion}
                                  />
                                )}
                              />
                              {/*<PrivateRoute*/}
                              {/*    userPermissions={this.state.userPermissions}*/}
                              {/*    shouldHavePermissions={[]}*/}
                              {/*    isAuthenticated={this.state.isAuthenticated}*/}
                              {/*    exact*/}
                              {/*    path="/manager/funnel/visit-sales"*/}
                              {/*    component={props =>*/}
                              {/*        <AsyncSalesAgentQueueSystemV2 {...props}*/}
                              {/*                                      agent_type={VISIT_SALES}*/}
                              {/*                                      contents={this.state.contents}*/}
                              {/*                                      userPermissions={this.state.userPermissions}*/}
                              {/*                                      showMessage={this.showMessage}*/}
                              {/*                                      user={this.state.user}*/}
                              {/*                                      cities={cities ? cities : []}*/}
                              {/*                                      agentid={this.state.agentid}*/}
                              {/*                                      userid={this.state.userid}*/}
                              {/*                                      agent={this.state.agent}/>}*/}
                              {/*/>*/}
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/funnel/bd-board"
                                component={(props) => (
                                  <AsyncAgentBdDashboard
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    cities={cities ? cities : []}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["mytasks:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/crm/funnel/:id/update"
                                component={(props) => (
                                  <AsyncFunnelUpdator
                                    {...props}
                                    cities={cities}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/admin/rolegroup/list"
                                component={(props) => (
                                  <AsyncRoleGroupList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/admin/module/list"
                                component={(props) => (
                                  <AsyncModuleList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/admin/partner-app/module/list"
                                component={(props) => (
                                  <AsyncPartnerAppModuleList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/approvals/approval-agent/list"
                                component={(props) => (
                                  <AsyncApprovalAgentList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/approvals/approval/list"
                                component={(props) => (
                                  <AsyncApprovalList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/admin/permission/list"
                                component={(props) => (
                                  <AsyncPermissionList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/agents/list"
                                component={(props) => (
                                  <AsyncAgentList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    cities={cities ? cities : []}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/agentonline/list"
                                component={(props) => (
                                  <AsyncAgentOnlineList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    cities={cities ? cities : []}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/agents/call-data"
                                component={(props) => (
                                  <AsyncAgentCallData
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    cities={cities ? cities : []}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              {/*venueonboarding*/}
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["onboarding:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/venue/onboarding-list"
                                component={(props) => (
                                  <AsyncPreVenueList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    cities={cities ? cities : []}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[
                                  "venueonboardings:update",
                                ]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/onboarding-venue/:id/update"
                                component={(props) => (
                                  <AsyncPreVenue
                                    {...props}
                                    showMessage={this.showMessage}
                                    cities={cities ? cities : []}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={[
                                  "venueonboardings:update",
                                ]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/onboarding-venue/:id/update"
                                component={(props) => (
                                  <AsyncPreVenue
                                    {...props}
                                    showMessage={this.showMessage}
                                    cities={cities ? cities : []}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["onboarding:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/venue/tracker-list"
                                component={(props) => (
                                  <AsyncVenueTrackerList
                                    showMessage={this.showMessage}
                                    cities={cities ? cities : []}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["venues:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/notfoundvenue/list"
                                component={(props) => (
                                  <NotFoundVenueList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    user={this.state.user}
                                    showMessage={this.showMessage}
                                    agentid={this.state.agentid}
                                    cities={cities}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["venues:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/futureevent/list"
                                component={(props) => (
                                  <FutureEventList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["mytasks:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/myfeedback/list"
                                component={(props) => (
                                  <AsyncMyFeedbackList
                                    {...props}
                                    showMessage={this.showMessage}
                                    userPermissions={this.state.userPermissions}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    cities={cities}
                                  />
                                )}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["mytasks:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/salescrm/bundleset/list"
                                component={(props) => (
                                  <AsyncBundleSetList
                                    {...props}
                                    cities={cities}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                  />
                                )}
                              />
                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["mytasks:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/salescrm/bundleset/:id/update"
                                component={(props) => (
                                  <AsyncBundleSetUpdator
                                    {...props}
                                    cities={cities}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                  />
                                )}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["mytasks:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/crm/funnelsystem/list"
                                component={(props) => (
                                  <AsyncFunnelSystemList
                                    {...props}
                                    cities={cities}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                  />
                                )}
                              />

                              <Redirect
                                exact
                                from="/manager/venue/featured"
                                to={
                                  "/manager/business-development/venue-partners"
                                }
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["admin:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                path="/manager/agents/list"
                                component={(props) => (
                                  <AsyncAgentList
                                    {...props}
                                    userPermissions={this.state.userPermissions}
                                    showMessage={this.showMessage}
                                    cities={cities ? cities : []}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                    userid={this.state.userid}
                                    agent={this.state.agent}
                                  />
                                )}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["onboarding:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/venue/tracker-list"
                                component={AsyncVenueTrackerList}
                              />

                              <PrivateRoute
                                userPermissions={this.state.userPermissions}
                                shouldHavePermissions={["bd-role:read"]}
                                isAuthenticated={this.state.isAuthenticated}
                                exact
                                path="/manager/access/list"
                                component={(props) => (
                                  <AsyncAdminAccessList
                                    {...props}
                                    showMessage={this.showMessage}
                                    user={this.state.user}
                                    agentid={this.state.agentid}
                                  />
                                )}
                              />

                              <Route component={NotFound} />
                            </Switch>
                          </div>
                        </div>
                      </div>
                      {this.state.isAuthenticated ? (
                        <footer role="contentinfo">
                          <Footer />
                        </footer>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Router>
        {this.state.isAuthenticated ? (
          <AppVersionModal isAuthenticated={this.state.isAuthenticated} />
        ) : null}

        <ToastContainer newestOnTop={true} />
        <Notifications options={{ zIndex: 10000 }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: getlocation(state),
    config: getConfigData(state),
  }
}

export default withRouter(connect(mapStateToProps)(App));
